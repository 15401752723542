// serve svg sprite
export function svgSprite() {

  var xhr = new XMLHttpRequest();
  xhr.open('get', '/static/img/icons-sprite.svg', true);
  xhr.onreadystatechange = function () {
    if (xhr.readyState != 4)
      return;
    var svg = xhr.responseXML.documentElement;
    svg = document.importNode(svg, true);
    document.body.appendChild(svg);
  };
  xhr.send();
}
