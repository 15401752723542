export function imageObserver() {
  if ('IntersectionObserver' in window) {
    // callback function to do animations
    const scrollImations = (entries, observer) => {
      entries.forEach((entry) => {
        // only do animation if the element is fully on screen
        if (entry.isIntersecting) {
          entry.target.classList.add('is--in-view');
        }
      });
    }

    // create the observer
    const options = {
      root: null,
      threshold: 0.5,
    };
    const observer = new IntersectionObserver(scrollImations, options);

    // target the elements to be observed
    const boxes = document.querySelectorAll('.block-image_block');
    boxes.forEach((box) => {
      observer.observe(box);
    });
  }
}
