export function externalLinks() {
  const container = document.querySelector('#content')
  var anchors = container.querySelectorAll('a')
  for (var i = 0; i < anchors.length; i++) {
    if (anchors[i].hostname !== window.location.hostname) {
      anchors[i].setAttribute('target', '_blank');
      anchors[i].setAttribute('rel', 'noreferrer');
    }
  }
}
